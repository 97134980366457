<template>
  <div>
    <v-card>
      <v-card-title>Listado de cierres</v-card-title>
      <v-card-text>
        <div>
          <v-row >
            <v-col class="text-right">
              <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    class="mx-2"
                    color="blue darken-1"
                    dark
                    right
                    fab
                    small
                    @click="dialog = true"
                    v-bind="attrs"
                    v-on="on"
                  >
                    <i class="material-icons">add</i>
                  </v-btn>
                </template>
                <span>Agregar Cierre</span>
              </v-tooltip>
            </v-col>
          </v-row>
        </div>
      </v-card-text>
    </v-card>
    <div class="card">
      <div class="card-header card-header-primary card-header-icon">
        <div class="card-icon" style="background: #1565C0 !important;">
          <i class="material-icons">list</i>
        </div>
        <h4 class="card-title">
          <div class="row">
            <div class="col-md-6">
              Listado de Cierres
            </div>
            <div class="col-md-6">
              <v-text-field
                v-model="search"
                append-icon="mdi-magnify"
                label="Escriba Para buscar Cierre"
                single-line
                hide-details
              ></v-text-field>
            </div>
          </div>
        </h4>
      </div>
      <div class="card-body">
        <div class="toolbar">
          <!--        Here you can write extra buttons/actions for the toolbar              -->
        </div>
        <div class="material-datatables">
          <v-data-table 
            :headers="cierresListHeaders"
            :items="cierresListBody"
            class="elevation-1 mx-0"
            label="Escriba Para buscar Cierre" 
            no-data-text="Listado de Cierres"
            fixed-header
            :search="search"
            :header-props="{ sortIcon: 'arrow_upward' }"
            :footer-props="{
              showFirstLastPage: true,
              firstIcon: 'first_page',
              lastIcon: 'last_page',
              prevIcon: 'navigate_befores',
              nextIcon: 'navigate_next'
              //'items-per-page-options':[5,10,20],
            }"
          >
            <template v-slot:item.month="{ item }">
              <span>{{ months[item.month - 1].nombre }}</span>
            </template>
            <template v-slot:item.inicio="{ item }">
              <span>{{ formatDate(item.inicio) }}</span>
            </template>
            <template v-slot:item.fin="{ item }">
              <span>{{ formatDate(item.fin) }}</span>
            </template>
            <template v-slot:[`item.actions`]="{item}">
              <v-tooltip left>
                <template v-slot:activator="{ on }">
                  <v-icon
                    medium
                    class="mr-2"
                    style="cursor:pointer;color:orange;"
                    @click="editCierre(item, false)"
                    v-on="on"
                  >
                    edit
                  </v-icon>
                </template>
                <span>Editar cierre</span>
              </v-tooltip>
              <v-tooltip left>
                <template v-slot:activator="{ on }">
                  <v-icon
                    medium
                    @click="deleteCierre(item)"
                    v-on="on"
                    style="cursor:pointer;color:red;"
                  >
                  delete
                  </v-icon>
                </template>
                <span>Eliminar cierre</span>
              </v-tooltip>
            </template>
          </v-data-table>
        </div>
      </div>
    </div>
    <!-- Modal Nuevo Cierre -->
    <v-dialog
      v-model="dialog"
      width="800px"
      @click:outside = "closeModal"
    >
      <v-card>
        <v-card-title class="blue darken-1">{{ title }}</v-card-title>
        <v-card-text>
          <v-row class="mx-2">
            <v-col
              class="align-center justify-space-between"
              cols="4"
            >
              <v-select 
                prepend-icon="mdi-factory"
                v-model="cierre.year"
                :items="years"
                item-text="nombre"
                item-value="id"
                label="Año"
                color="blue darken-3"
              >
              </v-select>
            </v-col>
            <v-col cols="4">
              <v-select 
                prepend-icon="mdi-factory"
                v-model="cierre.month"
                :items="months"
                item-text="nombre"
                item-value="id"
                label="Mes"
                color="blue darken-3"
                @change="setInicioTermino"
              >
              </v-select>
            </v-col>
            <v-col cols="4">
              <v-menu
                v-model="menu1"
                :close-on-content-click="false"
                :nudge-right="40"
                transition="scale-transition"
                offset-y
                min-width="290px"
              >
                <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="inicio_formated"
                  label="Fecha de Inicio"
                  prepend-icon="mdi-calendar"
                  readonly
                  v-bind="attrs"
                  v-on="on"
                ></v-text-field>
                </template>
                <v-date-picker
                  v-model = "cierre.inicio"
                  @input = "menu1 = false"
                  locale = "es-cl"
                  prev-icon="chevron_left"
                  next-icon="chevron_right"
                ></v-date-picker>
              </v-menu>
            </v-col>
            <v-col cols="4">
              <v-menu
                v-model="menu2"
                :close-on-content-click="false"
                :nudge-right="40"
                transition="scale-transition"
                offset-y
                min-width="290px"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="termino_formated"
                    label="Fecha de Termino"
                    prepend-icon="mdi-calendar"
                    readonly
                    v-bind="attrs"
                    v-on="on"
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model = "cierre.fin"
                  @input = "menu2 = false"
                  locale = "es-cl"
                  prev-icon="chevron_left"
                  next-icon="chevron_right"
                ></v-date-picker>
              </v-menu>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-btn
            @click="closeModal"
          >Cerrar</v-btn>
          <v-btn
            color="primary"
            @click="saveCierre"
          >Grabar</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- / Modal -->

    <v-snackbar
      v-model="snackbar"
      :timeout="5000"
      :color="color"
    >
      {{ validation_message }}
      <template v-slot:action="{ attrs }">
        <v-btn
          color="white"
          text
          v-bind="attrs"
          @click="snackbar = false"
        >
          Cerrar
        </v-btn>
      </template>
    </v-snackbar>
  </div>
</template>
<script>
  import {mapState, mapMutations} from 'vuex'

  export default {
    data: () => ({
      title: 'Nueva Cierre',
      color: 'green',
      snackbar: false,
      errors: [],
      validation_message: '',
      success: null,
      search: null,
      menu1: null,
      menu2: null,
      dialog: false,
      cierreIndex: -1,
      years: [],
      months: [],
      cierre: {
        id: 0,
        year: 0,
        month: 0,
        inicio: null,
        fin: null
      },
      cierresListHeaders: [
        {
          text: 'Id',
          align: 'center',
          sortable: true,
          value: 'id',
          class: "blue-grey darken-4 white-text"
        },
        {
          text: 'Año',
          align: 'center',
          sortable: true,
          value: 'year',
          class: "blue-grey darken-4 white-text"
        },
        {
          text: 'Mes',
          align: 'center',
          sortable: true,
          value: 'month',
          class: "blue-grey darken-4 white-text"
        },
        {
          text: 'Fecha Inicio',
          align: 'center',
          sortable: true,
          value: 'inicio',
          dataType: "Date",
          class: "blue-grey darken-4 white-text"
        },
        {
          text: 'Fecha Termino',
          align: 'center',
          sortable: true,
          value: 'fin',
          dataType: "Date",
          class: "blue-grey darken-4 white-text"
        },
        {
          text: 'Acciones',
          align: 'center',
          sortable: false,
          value: 'actions',
          class: "blue-grey darken-4 white-text"
        },
      ],
      cierresListBody: [],
      botones:[
        {boton:'crear',tooltip:'Nueva Cierre'},
        //{boton:'cargar',tooltip:'Carga Masiva'},
        //{boton:'eliminar',tooltip:'Borrar Todo'}
      ]
    }),
    mounted() {
        this.loadCierres();
        this.cierre.year = this.moment().format('YYYY');
        let yini = parseInt(this.cierre.year) - 10;
        let yend = parseInt(this.cierre.year) + 1;
        for(let y = yini; y <= yend; y++){
          this.years.push({id: y, nombre: y});
        }
        this.months = [
          {id: 1, nombre: 'Enero'},
          {id: 2, nombre: 'Febrero'},
          {id: 3, nombre: 'Marzo'},
          {id: 4, nombre: 'Abril'},
          {id: 5, nombre: 'Mayo'},
          {id: 6, nombre: 'Junio'},
          {id: 7, nombre: 'Julio'},
          {id: 8, nombre: 'Agosto'},
          {id: 9, nombre: 'Septiembre'},
          {id: 10, nombre: 'Octubre'},
          {id: 11, nombre: 'Noviembre'},
          {id: 12, nombre: 'Diciembre'},
        ];
        this.cierre.month = this.moment().clone().format('M');
        this.cierre.inicio  = this.moment().clone().startOf('month').format('YYYY-MM-DD');
        this.cierre.fin = this.moment().clone().endOf('month').format('YYYY-MM-DD');
    },
    methods:{
      setInicioTermino(){
        let ym = `${this.cierre.year}-${this.cierre.month}-01`;
        this.cierre.inicio  = this.moment(ym).clone().startOf('month').format('YYYY-MM-DD');
        this.cierre.fin = this.moment(ym).clone().endOf('month').format('YYYY-MM-DD');
      },
      async loadCierres(){
        this.mostrarLoading('Cargando listado de cierres...')
        let url = `${this.base_url}cierres`;
        await this.axios.get(url, this.headers).then((response)=>{
            this.cierresListBody = response.data;
            this.ocultarLoading()
        }).catch((error)=>{
            console.log(error);
            this.ocultarLoading()
        });
      },
      async saveCierre(){
        // validation
        this.errors = [];
        if(!this.cierre.year){
          this.errors.push("Debe indicar año");
        }
        if(!this.cierre.month){
          this.errors.push("Debe indicar mes");
        }
        if(!this.cierre.inicio){
          this.errors.push("Debe indicar fecha de inicio");
        }
        if(!this.cierre.fin){
          this.errors.push("Debe indicar fecha de termino");
        }
        if(this.errors.length > 0){
          this.validation_message = this.errors.join();
          this.showSnackBar(true);
          return;
        }
        let url = `${this.base_url}cierres`;
        let cierreData = {
          'year': this.cierre.year,
          'month': this.cierre.month,
          'inicio': this.cierre.inicio,
          'fin': this.cierre.fin,
        };
        if(this.cierre.id > 0){
          url = url + "/" + this.cierre.id;
          await this.axios.put(url, cierreData, this.headers).then((response)=>{
            console.log(response);
            this.loadCierres();
            this.showSnackBar(false);
          }).catch((error)=>{
            this.validation_message = error;
            this.showSnackBar(true);
          });
        }else{
          await this.axios.post(url, cierreData, this.headers).then((response)=>{
            console.log(response);
            this.loadCierres();
            this.showSnackBar(false);
          }).catch((error)=>{
            this.errors = error.response.data;
            this.validation_message = this.errors.join();
            this.showSnackBar(true);
          });
        }
        this.cleanForm();
      },
      editCierre(item) {
        this.cierreIndex = this.cierresListBody.indexOf(item);
        this.cierre = Object.assign({}, item);
        this.dialog = true;
        this.title = "Editar Cierre";
      },
      async deleteCierre(item) {
        this.$confirm('Desea borrar este cierre?').then(res => {
          if(res){
            let url = `${this.base_url}cierres/${item.id}`;
            this.axios.delete(url, this.headers).then((response)=>{
              console.log(response);
              this.loadCierres();
              this.showSnackBar(false);
            }).catch((error)=>{
              this.validation_message = error;
              this.showSnackBar(true);
            });
          }
        });
      },
      showSnackBar(error){
        if(error){
          this.color = 'red';
        }else{
          this.color = 'green';
          this.validation_message = "Ejecutado exitosamente!";
        }
        this.snackbar = true;
      },
      closeModal(){
        this.cleanForm();
        this.dialog = false;
        this.error = null;
        this.title = "Nuevo Cierre";
      },
      cleanForm(){
        this.cierre = {
          id: 0,
          nombre: '',
        };
        this.dialog = false;
      },
      formatDate(date) {
        if (!date) return '';
        const [year, month, day] = date.split('-');
        return `${day}-${month}-${year}`;
      },
      formatISO(date) {
        if (!date) return '';
        const [day, month, year] = date.split('-');
        return `${year}-${month}-${day}`;
      },
      ...mapMutations(['actualizarBaseUrl', 'mostrarLoading', 'ocultarLoading']),
    },
    computed:{
      ...mapState(['loading', 'base_url', 'headers']),
      inicio_formated () {
        return this.formatDate(this.cierre.inicio)
      },
      termino_formated () {
        return this.formatDate(this.cierre.fin)
      },
    },
    components:{
      
    }
  }
</script>